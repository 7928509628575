import React from "react";
import { pageWidths } from "../page-widths";
import "./shared.css";
import { Helmet } from "react-helmet";
import logo from "../images/VerticalWordMark.Main.png"

function About() {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div
      style={{
        marginLeft:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        marginRight:
          width >= pageWidths.mediumWidth
            ? "10vw"
            : width >= pageWidths.smallWidth
            ? "1vw"
            : "0vw",
        paddingLeft:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
        paddingRight:
          width >= pageWidths.mediumWidth
            ? "4vw"
            : width >= pageWidths.smallWidth
            ? ".5vw"
            : "0vw",
      }}
    >
      <h1
          className="headerTextStyle"
          style={{
            textAlign: "center",
            paddingTop: width > pageWidths.smallWidth ? "1em" : "none",
            paddingBottom: "1em",
          }}
        >
          ABOUT US
        </h1>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Radix Software Solutions | About</title>
        <meta
          name="description"
          content="Radix Software Solutions has years of experience working closly with clients to improve processes. Learn a bit more about us and how we can help you with any business needs!"
        />
        <link
          rel="canonical"
          href="https://www.radixsoftwaresolutions.com/about"
        />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexDirection: width > 990 ? "row" : "column",
          alignItems: "center",
        }}
      >
        {/* Owner Picture */}
        <div
          style={{
            width: "100%",
            maxWidth: "20em",
            minWidth: width > 400 ? "20em" : "10em",
          }}
        >
          <img
            src={logo}
            alt="Radix Software Solutions LLC Logo"
            width={"100%"}
          />
        </div>

        {/* Hello Introduction */}
        <div
          style={{
            paddingLeft: "3em",
            paddingRight: "3em",
            paddingTop: width > 990 ? "2em" : "1em",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="paragraphTextStyle">
            At Radix Software Solutions LLC, we bring years of expertise in Custom Data Integrations, Custom Reporting, and the development and support of bespoke SaaS platforms.
            <br />
            <i style={{ color: "#278a90" }}>
            Our mission is to simplify complex challenges while maintaining a steadfast commitment to transparency and integrity.

            </i>
          </p>
        </div>
      </div>
      {/* Radix Introduction */}
      <div
        style={{
          paddingLeft: "3em",
          paddingRight: "3em",
          marginTop: "4em",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderLeft: "1em solid #d65e38",
          borderRight: "1em solid #d65e38",
        }}
      >
        <p className="paragraphTextStyle">
        Headquartered in Kentucky, we proudly serve local businesses and a diverse clientele across the nation. Radix was founded with a vision to provide tailored solutions that prioritize quality, reliability, and client satisfaction.
          <br />
          <br />
          We are proud to keep all our operations, including infrastructure and team members, within the United States. This approach ensures that we deliver consistently high-quality results and build lasting partnerships with our clients.        </p>

      </div>
    </div>
  );
}

export default About;
