import React from "react";
import DataAndReportingIcon from "../svgs/data-and-reporting-icon.svg";
import DesktopAppIcon from "../svgs/desktop-app-icon.svg";
import MobileAppIcon from "../svgs/mobile-app-icon.svg";
import WebsiteIcon from "../svgs/website-icon.svg";
import { pageWidths } from "../page-widths";
import "./shared.css";
import { Helmet } from "react-helmet";

function Services() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div
      style={{
        alignItems: "center",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Radix Software Solutions | Services</title>
        <meta
          name="description"
          content="We offer a wide variety of services ranging from custom reporting, web design or hosting, and desktop/mobile application development."
        />
        <link
          rel="canonical"
          href="https://www.radixsoftwaresolutions.com/services"
        />
      </Helmet>
      <h1
        className="headerTextStyle"
        style={{ paddingTop: width > pageWidths.smallWidth ? "1em" : "none" }}
      >
        SERVICES
      </h1>
      {/* Data and Reporting */}
      <div
        style={{
          marginLeft: width >= pageWidths.mediumWidth ? "10vw" : "1vw",
          marginRight: width >= pageWidths.mediumWidth ? "10vw" : "1vw",
          paddingLeft: width >= pageWidths.mediumWidth ? "4vw" : ".5vw",
          paddingRight: width >= pageWidths.mediumWidth ? "4vw" : ".5vw",
          paddingTop: "1em",
          display: "flex",
          flexDirection: width >= pageWidths.smallWidth ? "row" : "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            padding: "1em",
            width: width >= pageWidths.smallWidth ? "50vw" : "90vw",
            alignItems: "flex-start",
            paddingRight: width >= pageWidths.smallWidth ? "5em" : "none",
            borderLeft: "1em solid #d65e38",
          }}
        >
          <h1 className="headerTextStyle">Data and Reporting</h1>
          <br />
          <p className="paragraphTextStyle">
            Customized reporting enhances visibility across systems, delivering relevant data from a single source. As software stacks grow more complex, we simplify data gathering and keep you informed with up-to-date, tailored reports.
          </p>

        </div>
        <div
          style={{
            display: "flex",
            width: "50vw",
            maxHeight: "15em",
            paddingLeft: width >= pageWidths.smallWidth ? "1em" : "0em",
            paddingTop: width >= pageWidths.smallWidth ? "0em" : "1em",
            paddingRight: width >= pageWidths.smallWidth ? "2em" : "0em",
          }}
        >
          <img width="100%" src={DataAndReportingIcon} alt="Stats" />
        </div>
      </div>

      {/* Websites */}
      <div
        style={{
          marginLeft: width >= pageWidths.mediumWidth ? "10vw" : "1vw",
          marginRight: width >= pageWidths.mediumWidth ? "10vw" : "1vw",
          paddingLeft: width >= pageWidths.mediumWidth ? "4vw" : ".5vw",
          paddingRight: width >= pageWidths.mediumWidth ? "4vw" : ".5vw",
          paddingTop: "1em",
          display: "flex",
          flexDirection: width >= pageWidths.smallWidth ? "row" : "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "50vw",
            maxHeight: "15em",
            paddingRight: width >= pageWidths.smallWidth ? "1em" : "0em",
            paddingTop: width >= pageWidths.smallWidth ? "0em" : "1em",
            paddingLeft: width >= pageWidths.smallWidth ? "2em" : "0em",
          }}
          hidden={width >= pageWidths.smallWidth ? false : true}
        >
          <img
            width="85%"
            src={WebsiteIcon}
            alt="Custom Website Template Icon"
          />
        </div>
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            width: width >= pageWidths.smallWidth ? "50vw" : "90vw",
            padding: "1em",
            alignItems: "flex-end",
            paddingLeft: width >= pageWidths.smallWidth ? "5em" : "none",
            borderRight: "1em solid #d65e38",
          }}
        >
          <h1 className="headerTextStyle">Custom Integrations</h1>
          <br />
          <p className="paragraphTextStyle" style={{ textAlign: "right" }}>
            Managing complex software stacks is challenging, especially with custom processes. Radix Software Solutions ensures seamless data integration, from syncing Warehouse Management with your Website to connecting Accounting with Shipping software.
          </p>

        </div>
        <div
          style={{
            display: "flex",
            width: "50vw",
            maxHeight: "25em",
            paddingLeft: width >= pageWidths.smallWidth ? "1em" : "0em",
            paddingTop: width >= pageWidths.smallWidth ? "0em" : "1em",
          }}
          hidden={width >= pageWidths.smallWidth ? true : false}
        >
          <img
            width="100%"
            src={WebsiteIcon}
            alt="Custom Website Template Icon"
          />
        </div>
      </div>

      {/* Desktop and Mobile Applications */}
      {/* <div
        style={{
          marginLeft: width >= pageWidths.mediumWidth ? "10vw" : "1vw",
          marginRight: width >= pageWidths.mediumWidth ? "10vw" : "1vw",
          paddingLeft: width >= pageWidths.mediumWidth ? "4vw" : ".5vw",
          paddingRight: width >= pageWidths.mediumWidth ? "4vw" : ".5vw",
          paddingTop: "1em",
          display: "flex",
          flexDirection: width >= pageWidths.smallWidth ? "row" : "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            width: width >= pageWidths.smallWidth ? "50vw" : "90vw",
            padding: "1em",
            paddingRight: width >= pageWidths.smallWidth ? "5em" : "none",
            borderLeft: "1em solid #d65e38",
            alignItems: "flex-start",
          }}
        >
          <h1 className="headerTextStyle" style={{ textAlign: "left" }}>
            Software Solutions
          </h1>
          <br />
          <p className="paragraphTextStyle">
            We have big plans in the upcoming years! Our first SaaS is planned to launch in late 2024, with more to follow!

            Be sure to follow us on social media to stay up to date with our software launches and updates!
          </p>
        </div>
        <div
          style={{
            display: "flex",
            width: "50vw",
            maxHeight: "25em",
            paddingLeft: width >= pageWidths.smallWidth ? "1em" : "0em",
            paddingTop: width >= pageWidths.smallWidth ? "0em" : "1em",
          }}
        >
          <img
            width="15%"
            src={MobileAppIcon}
            alt="Mobile Application Illustration."
          />
          <img
            width="75%"
            src={DesktopAppIcon}
            alt="Desktop Application Illustration."
          />
        </div>
      </div> */}
    </div>
  );
}

export default Services;
